@import './../../variable.scss';

.home {
    &.hide {
        background-color: #000;
        display: none;
    }


    display: none;
    @media(min-width: 1024px){
        display: flex;
        
    }
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 0;


    .scrollContainer{
        display: flex;
        overflow-x: hidden;
        cursor: url(./../../assets/core/group-371.svg) 64 45, auto;
    }
}
