@import './../../variable.scss';

.login {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: auto;
    background-color: $login;
    background-image: url(./../../assets/login/img-tablet-header.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    color: #fff;
    font-family: FSIndustrie; 

    @media(min-width:1024px) {
        background-image: url(./../../assets/login/img-desktop-1920-leftcolumn.png), url(./../../assets/login/img-desktop-1920-rightcolumn.png);
        background-size: contain;
        background-position: right, left;
        height: 100vh;
        min-height: unset;

        section{
            padding: 74px;

            p {
                
            }
        }
    }
    

    h1 {
        font-size: 0px;
        color: transparent;
    }

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100vw;
        padding: 7% 55px;
        margin: 0;
        box-sizing: border-box;
        background-color: $login;


        @media(min-width:1024px) {
            flex-direction: row;
            height: 100%;
            width: 60%;
            margin: 0 auto;

            div{
                h2{
                    font-size: 1.55vw;
                }
                p{
                    font-size: 24px;
                    font-size: 1.25vw;
                }
            }
        }

        div {
          max-width: 400px;
        

            img {
                display: block;
                margin: 0 auto;
                width: 28%;
                min-width: 266px;
                margin-bottom: 18px;
            }

            h2 {

                font-size: 24px;
                font-weight: 900;
                line-height: 1.2;
                letter-spacing: 3px;
                // margin: 0 5px;
                margin-top: 64.4px;
                text-transform: uppercase;
            }

            h3 {
              font-size: 16px;
              line-height: 1.28;
            }

            ul{
              margin: 0;
              padding-left: 1rem;
              font-size: 16px;
              font-weight: 300;
              line-height: 1.28;
            }

            p {
                margin: 0;
                margin-top: 19px;
                font-size: 16px;
                line-height: 1.25;
                font-weight: 300;
                line-height: 1.28;
            }


            @media screen and (min-width: 768px) {
                img {
                    width: 43%;
                    margin-bottom: 55px;
                }

                h2{
                    font-size: 24px;
                }
                p {
                    font-size: 16px;
                }
            }

            @media(min-width:1024px) {
                width: 44%;
                margin-left: 5%;
                max-width: unset;

                &.full {
                  width: 100%;
                  max-width: 400px;
                  margin: 0 auto;
                }

                img {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 115px;
                    min-width: unset;
                }
            }
        }
        form {       
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 400px;
            
            input, button {
                width: 100%;
                height: 48px;
                font-size: 20px;
                box-sizing: border-box;
                border: none;
            }
            .login__input {
                padding: 0 5%;
                margin: 7% 0 0;

                &::placeholder{
                    color: $placeholder-copy;
                }
            }

            label {
                width: 100%;
                margin: 0;
                margin-top: 6px;
                cursor: pointer;
            }

            .checkbox-list {
              display: flex;
              flex-direction: column;
            }

            input[type="checkbox"] {
                width: 20px;
                height: 20px;
                margin-right: 5%;
                border: none;
                cursor: pointer;

                label {
                    width: 90%;
                    margin: 0;
                    cursor: pointer;
                }
            }

            p {
                text-align: left;

                &.legal{
                  font-size: 10px;
                }
            }
            a {
                color: #fff;
                text-decoration: underline;
                cursor: pointer;
            }
            button {
                margin-top: 23px;
                text-transform: uppercase;
                background-color: transparent;
                border: solid #fff 3px;
                color: #fff;
                line-height: 1.28;
                letter-spacing: 3.6px;
                font-weight: 700;
                cursor: pointer;

                &:hover {
                  background-color: #fff;
                  color: black;
                }

                &.sign-up {
                    background-color: transparent;
                }
            }

            div {
                width: 100%;
                margin-left: 0;
                display: flex;
                align-items: flex-start;
                justify-content: center;
            }
            @media(min-width:1024px) {
                width: 56%;
                padding: 0 5%;
                max-width: unset;
                
                input, button {
                    width: 100%;
                    height: 68px;
                    font-size: 18px;
                }

            }
        }
    }
}