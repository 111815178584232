.socials{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    width: 100%;
    a{
        display: block;
        width: 25%;
        margin: 0 10%;

        @media(min-width: 1024px) {
            width: 15%;
            margin: 0 5%;
        }
    }
    img {
        width: 15%;
        width: 100%;
        // max-width: 70px;
        min-width: 34px;
    }
}