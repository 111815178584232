.menu {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    opacity: 0.94;
    background-color: #000;
    left: 150vw;
    transition: left ease-in-out .8s .2s, opacity ease-in .2s;
    opacity: 0;
    font-family: CorporateS, sans-serif;
    font-weight: bold;
    line-height: 1.54;
    letter-spacing: 2.78px;

    div {
        max-width: 768px;
        margin: auto;
        padding: 0 5%;

        .socials{
            width: 50%;
        }
    }

    button {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 3.5%;
        right: 2%;
        background-color: transparent;
        background-image: url(./../../../assets/core/group-23.svg);
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        color: transparent;
    }

    &.open {
        left: 0vw;
        opacity: 1;
    }

    ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 1.54;
        font-size: 28px;

        
        li {
            margin: .5vh 0;
            a {
                color: #fff;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
