@import "./../../variable.scss";

.subpage {
    width: 100vw;
    height: 0vh;
    top: 100vh;
    z-index: 5;
    display: flex;
    position: absolute;
    overflow-y: scroll;
    transition: height 0.5s ease-in-out, top 0.5s ease-in-out;

    &.open {
        height: 100vh;
        top: 0vh;
        // overflow-y: unset;

    }

    .subpage-inner {
        .main__header {
            img {
                width: 100%;
            }
        }
        >button {
            // display: none;
            position: absolute;
            background-image: url(./../../assets/subpage/path-35.svg);
            background-size: 25%;
            transform: rotate(90deg);
            background-repeat: no-repeat;
            background-position: center;
            color: transparent;
            opacity: 0.5;
            width: 67px;
            height: 67px;
            margin-top: -56px;
            border: none;
            cursor: pointer;

            @media(min-width: 768px){
                width: 97px;
                height: 97px;
            }

            &:last-of-type{
                position: fixed;
                bottom: 0;
                opacity: 0.2;
                transition: opacity .3s ease-in-out;

                &:hover{
                    opacity: 0.7;
                }
            }
        }
        .subpage-section-image {
            &--desktop {
                display: none;
            }
            @media (min-width: 1024px) {
                &--desktop {
                    display: inline-block;
                }
                &--mobile {
                    display: none;
                }
            }
        }

        .subpage__divider {
            width: 95%;
            height: 1px;
            background: #000;
            margin: 0 auto;
            margin: 5vw auto 5vw auto;
        }

        .citation {
            padding: 2vw 3vw 0 9vw;
            font-family: CorporateS;
            font-weight: 500;
            font-size: 12px;
            line-height: 3vw;
            
            @media(min-width: 768px){
                font-size: 1.1vw;
                line-height: 1.8vw;
            }
        }

        // max-width: 1280px;
        max-width: 100%;
        margin: 0 auto;

        .outer-grid-wrapper {
            // &.section_2, &.section_3, &.section_4 {
            //     max-width: 1280px;
            //     margin: 0 auto;
            // }

            .section__header {
                font-size: 36px;
                line-height: 36px;
                letter-spacing: 3.6px;
                font-family: CorporateS;

                @media (min-width: 1024px) {
                    font-size: 3.2vw;
                    line-height: 1.31;
                    letter-spacing: 6.48px;
                }

                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                text-align: left;
                color: $white;
                padding: 0px 3vw 0 7vw;
            }

            .grid-wrapper {
                display: grid;

                @media (min-width: 1024px) {
                    grid-template-columns: 50% 50%;
                }

                /*mobile default. removes 2 column layout*/
                grid-template-columns: 100%;

                .grid-inner {
                    position: relative;

                    .header-wrapper {
                        width: 100%;
                        text-align: center;

                        @media (min-width: 1024px) {
                            position: absolute;
                            width: initial;
                        }
                    }

                    img {
                        width: 100%;
                    }

                    .copy__wrapper {
                        padding: 3vw 9vw;

                        @media (min-width: 1024px) {
                            padding: initial;
                        }
                        p:first-of-type {
                            font-size: 24px;
                            line-height: 1.25;
                            font-family: CorporateS-Demi;

                            @media (min-width: 1024px) {
                                font-size: 2.4vw;
                                line-height: 3.2vw;
                                // font-size: 40px;
                                // line-height: 1;
                            }
                        }

                        p:last-of-type {
                            font-size: 16px;
                            line-height: 1.75;
                            font-family: CorporateS-Demi;

                            @media (min-width: 1024px) {
                                font-size: 1.5vw;
                                line-height: 2.2vw;
                                // font-size: 20px;
                                // line-height: 1.5;
                            }
                        }
                    }
                }
            }
        }

        .outer-grid-wrapper:nth-of-type(even) {
            .grid-inner:nth-of-type(even) {
                order: initial;
                @media (min-width: 1024px) {
                    order: -1;
                    padding: 0px 3vw 0 7vw;
                }
            }
        }

        .outer-grid-wrapper:nth-of-type(odd) {
            .grid-inner:nth-of-type(even) {
                @media (min-width: 1024px) {
                    padding: 0px 7vw 0 3vw;
                }
            }
        }

        h2 {
            // font-size: 70px;
            font-size: 16vw;
            line-height: 1.89;
            letter-spacing: 3.5px;

            @media (min-width: 1024px) {
                line-height: 0.9em;
                letter-spacing: 0.6vw;
                font-size: 7.5vw;
            }
            font-family: CorporateS;
            text-transform: uppercase;
            color: #ffffff;
            margin: 0;

            span {
                // font-size: 60px;
                font-size: 15vw;
                line-height: 0.45;
                font-family: dearJoe6;
                left: 0;
                right: 0;
                letter-spacing: -0.28vw;

                @media (min-width: 1024px) {
                    font-size: 6.5vw;
                    line-height: 0.34;
                }
                font-weight: normal;
                text-transform: initial;
                -webkit-text-stroke: 0;
                position: absolute;
                color: $yellow;
                width: 100%;
            }
        }
    }

    .section_1 {
        font-family: CorporateS;
        h2 {
            line-height: inherit;

            font-family: CorporateS;
            font-size: 90px;
            font-size: 16vw;
            text-align: center;

            span {
                position: absolute;
                text-transform: capitalize;
                z-index: 1;
                width: unset;
                color: $charcoal;
                font-size: 6vw;
                line-height: 0.34;
                margin-left: -25%;
            }
        }
        h4 {
            font-size: 19.5px;
            font-weight: 300;
            line-height: 1;

            @media(min-width: 1024px) {
                font-size: 1.7vw;
                line-height: 2.2vw;
            }
        }
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.75;

            @media(min-width: 1024px) {
                font-size: 1.5vw;
                line-height: 2.2vw;
            }
        }
        .subpage__hero {
            position: relative;
            overflow: hidden;
            height: 570px;
            padding: unset;
            img {
                width: auto;
                height: 120%;
                top: 0;
                left: 0;
                right: 0;
                // margin: 0 auto;
                position: absolute;

                @media(min-width: 620px){
                    top: unset;
                    width: 100%;
                    height: auto;
                    bottom: -19.5vw;
                }
            }
        }
        button {
            background-image: url(./../../assets/subpage/path-35.svg);
            background-repeat: no-repeat;
            background-position: center;
            color: transparent;
            position: absolute;
            width: 67px;
            height: 67px;
            margin-top: -60px;
            border: none;
            cursor: pointer;

            @media(min-width: 768px){
                width: 97px;
                height: 97px;
                margin-top: -90px;
            }
        }
        section {
            padding: 3vw 9vw 0 9vw;

            .subpage__subtitle {
                font-family: CorporateS;
                text-transform: uppercase;
                font-weight: 300;
                letter-spacing: 0.3vw;
                font-size: 4vw;

                // font-size: 60px;
                // line-height: 1.17;
                @media (min-width: 1024px) {
                    font-size: 5vw;
                }
            }
            .subpage__callout,
            .subpage__callout2 {
                line-height: unset;

                display: inline-block;
                // margin-top: 10vw;

                @media (min-width: 1024px) {
                    display: flex;
                }
                img {
                    position: absolute;
                    width: 100%;
                    z-index: -1;
                }
                h3 {
                    display: block;
                    font-family: dearJoe6;
                    color: $yellow;
                    font-size: 80px;
                    font-size: 6vw;
                    font-weight: 500;
                    white-space: nowrap;

                    // margin: 0 auto 2vw auto;
                    margin-left: 21vw;
                    @media (min-width: 1024px) {
                        margin: 0;
                    }
                }
                p {
                    font-family: CorporateS-Demi;
                    color: #fff;
                    font-size: 36px;
                    font-size: 3.5vw;
                    margin: 0 auto;
                    text-align: center;
                    line-height: unset;
                    @media (min-width: 1024px) {
                        text-align: left;
                    }
                }
            }
        }
    }

    &.editors-letter {
        background: $editors-letter;
        background: linear-gradient(to top, #e1175f 3%, #c883a7 46%, #c881a9 93%, #c881a9 93%);
        background-attachment: local;
        .section_1 {
            .subpage__hero {
                background-color: $editors-letter;
                img {
                    width: auto;
                    height: 100%;
                    bottom: 0;
                    left: -108px;
                    right: 0;
                    margin: 0 auto;
                }
            }
            button {
                background-color: #c883a7;
            }
            section {
                padding-bottom: 1vw;
                h2 {
                    margin-top: 5vw;
                    font-size: 15vw;

                    span {
                        line-height: 0.34;
                        margin-left: -50%;
                        margin-top: 6vw;
                        font-size: 7vw;
                    }
                }

                .subpage__subtitle {
                    color: #fff;
                    letter-spacing: 3.6px;
                }
                .subpage__subtitle2 {
                    font-family: CorporateS-Demi;
                    font-weight: 500;
                    color: #fff;
                    padding: 20px 0;
                    line-height: 1.17;
                    font-size: 3.5vw;

                    @media (min-width: 1024px) {
                        // font-size: 5vw;
                    }
                }
                .subpage__callout,
                .subpage__callout2 {
                    flex-direction: column;
                    overflow: visible;
                    img {
                        width: 68%;
                        padding: 0 6%;
                    }
                    h3 {
                        text-align: center;
                        margin-left: -45%;
                        margin-bottom: 5vw;
                        span {
                            position: absolute;
                            font-family: CorporateS;
                            font-size: 9vw;
                            letter-spacing: 10.6px;
                            text-transform: uppercase;
                            color: #fff;
                            margin-left: -14%;
                            margin-top: 5%;
                            z-index: -1;
                        }
                    }
                    p {
                        padding: 0 15%;
                    }
                }
                .subpage__callout {
                    img {
                        width: 68%;
                        padding: unset;
                        margin-left: -7%;
                    }
                    h3 {
                        span {
                            font-weight: bold;
                        }
                    }
                }
                .subpage__el-logo {
                    display: block;
                    width: 90%;
                    max-width: 500px;
                    margin: 0 auto 17vw;
                    // -bottom: 3vw;
                }
            }
        }
    }

    &.rational-reset {
        .section_1 {
            h2 {
                span {
                    margin-left: -29vw;
                    margin-top: 9vw;
                    font-size: 9vw;
                }

                font-size: 22vw;
                line-height: inherit;
            }
            button {
                background-color: #498bb0;
            }
            section {
                .subpage__callout {
                    align-items: flex-end;
                    justify-content: center;

                    margin-top: 12%;
                    img {
                        // margin-left: -21%;
                        // margin-top: -12%;
                        // width: 50%;
                        margin-left: -31%;
                        margin-top: -15%;
                        width: 61%;
                    }
                    h3 {
                        text-align: right;
                        width: 43%;
                        padding: 0 40px;
                        margin-right: 15%;
                    }
                    p {
                        width: 80%;
                        text-align: center;
                        margin-top: -5vw;
                        @media (min-width: 1024px) {
                            text-align: left;
                            width: 30%;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .section_2 {
            &.outer-grid-wrapper {
                .grid-wrapper {
                    margin-top: 15vw;
                }
                .grid-wrapper:nth-of-type(2) {
                    .grid-inner:nth-of-type(even) {
                        @media (min-width: 1024px) {
                            padding: 0px 7vw 0 3vw;
                        }
                    }

                    .grid-inner {
                        order: initial;

                        @media (min-width: 1024px) {
                            order: -1;
                        }
                    }
                }
            }

            .grid-wrapper:nth-of-type(2) {
                .grid-inner:last-of-type {
                    .header-wrapper {
                        top: -6.5vw;
                        left: -33.5vw;
                        white-space: pre;

                        h2 {
                            font-size: 9vw;
                            @media (min-width: 1024px) {
                                font-size: 7.5vw;
                            }
                        }

                        span {
                            @media (min-width: 1024px) {
                                left: -19.5vw;
                            }

                            top: -3.5vw;
                            z-index: 1;
                        }
                    }
                }
            }

            .grid-wrapper:nth-of-type(3) {
                .grid-inner:last-of-type {
                    .header-wrapper {
                        top: -7vw;
                        left: 3.5vw;

                        h2 {
                            // font-size: 8vw;
                            font-size: 10vw;
                            letter-spacing: 0.1vw;

                            span {
                                @media (min-width: 1024px) {
                                    left: -29.9vw;
                                }

                                top: -2.7vw;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }

        .section_3 {
            .header-wrapper {
                top: 4.3vw;
                left: 2vw;

                span {
                    font-size: 12vw;
                    top: 0.4vw;
                    @media (min-width: 1024px) {
                        left: 0vw;
                        top: -2.5vw;
                        font-size: 5.8vw;
                    }
                }
            }

            .copy__wrapper {
                @media (min-width: 1024px) {
                    margin-top: 13vw;
                }
            }
        }

        .section_4 {
            .grid-wrapper {
                margin-top: 10vw;

                .header-wrapper {
                    top: -6.7vw;
                    left: 3vw;
                    white-space: pre;

                    h2 {
                        font-size: 10.5vw;
                        margin-top: -30px;
                        margin-bottom: 50px;
                        span {
                            top: 9vw;
                        }
                        @media (min-width: 1024px) {
                            
                                font-size: 8vw;
                                margin: 0;
                                span{
                                    top: 1vw;
                                    left: -7.4vw;
                                    top: 6vw;
                                    font-size: 8.3vw;
                                    z-index: -1;
                                }
                            
                        }
                    }
                }

                .copy__wrapper {
                    @media (min-width: 1024px) {
                        margin-top: 7vw;
                    }
                }
            }
        }

        background: $rational-reset;
        background: linear-gradient(to bottom, #2b8db2 2%, #c883a7 32%, #c881a9 93%, #c881a9 93%);
        background-attachment: local;
    }

    &.self-stasis {
        .section_1 {
            h2 {
                font-size: 20vw;
                line-height: inherit;

                span {
                    // margin-left: -54%;
                    // margin-top: 8%;
                    margin-left: -48%;
                    margin-top: 9vw;
                    font-size: 9vw;
                }
            }
            button {
                background-color: #e0dd1f;
            }
            section {
                .subpage__callout {
                    // align-items: flex-end;
                    align-items: center;
                    justify-content: center;
                    img {
                        margin-left: -17%;
                        margin-top: -10%;
                        width: 50%;
                    }
                    h3 {
                        text-align: right;
                        width: 50%;
                        padding: 0 40px;
                        margin-top: 10vw;
                    }
                    p {
                        width: 80%;
                        text-align: center;
                        margin-top: -5vw;

                        @media (min-width: 1024px) {
                            width: 40%;
                            text-align: left;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .section_2 {
            .outer-grid-wrapper:nth-of-type(even) {
                .grid-inner:nth-of-type(even) {
                    order: initial;
                    @media (min-width: 1024px) {
                        order: -1;
                        padding: 0px 7vw 0 3vw;
                    }
                }
            }

            .outer-grid-wrapper:nth-of-type(odd) {
                .grid-inner:nth-of-type(even) {
                    @media (min-width: 1024px) {
                        padding: 0px 3vw 0 7vw;
                    }
                }
            }

            span {
                @media (min-width: 1024px) {
                    left: 11vw;
                    top: 6vw;
                }
            }

            .grid-inner:last-of-type {
                .header-wrapper {
                    top: 0.4vw;
                    left: 3vw;

                    h2{
                        margin-top: -5vw;
                        margin-bottom: 5vw;
                        span {
                            margin-top: 20vw;
                        }

                        @media(min-width: 1024px) {
                            margin: 0;
                             span{
                                 margin: 0;
                             }   
                                
                        }
                    }
                }

                .copy__wrapper {
                    @media (min-width: 1024px) {
                        margin-top: 11vw;
                    }
                }
            }
        }

        .section_3 {
            span {
                @media (min-width: 1024px) {
                    left: -1vw;
                    top: -2.5vw;
                }
            }

            .grid-inner:last-of-type {
                .header-wrapper {
                    top: 2.9vw;
                }

                .copy__wrapper {
                    @media (min-width: 1024px) {
                        margin-top: 12vw;
                    }
                }
            }
        }

        .section_4 {
            span {
                @media (min-width: 1024px) {
                    left: -2vw;
                    top: -2.5vw;
                }
            }

            .grid-inner:last-of-type {
                .header-wrapper {
                    top: 4.5vw;
                    left: 4vw;
                }

                .copy__wrapper {
                    @media (min-width: 1024px) {
                        margin-top: 12vw;
                    }
                }
            }
        }

        background: $self-stasis;
        background: linear-gradient(to bottom, #e0dd1f 12%, #d5b161 22%, #c883a7 47%, #c881a9 93%, #c881a9 93%);
        background-attachment: local;
    }

    &.viva-irl {
        .section_1 {
            h2 {
                font-size: 23vw;
                line-height: inherit;

                span {
                    // margin-left: -30%;
                    // margin-top: 9%;

                    margin-left: -43%;
                    margin-top: 10%;
                    font-size: 9vw;
                }
            }
            button {
                background-color: #cd3a6e;
            }
            section {
                .subpage__callout {
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    margin-top: 14%;
                    img {
                        margin-left: -17%;
                        margin-top: -5%;
                        width: 50%;
                    }
                    h3 {
                        text-align: left;
                        width: 62%;
                        line-height: 1;
                        padding: 0 40px;
                        white-space: unset;
                        font-weight: normal;
                    }
                    p {
                        // width: 30%;
                    }
                }
            }
        }
        .section_2 {
            &.outer-grid-wrapper {
                .grid-wrapper {
                    margin-top: 15vw;

                    .grid-inner {
                        .header-wrapper {
                            top: -7.5vw;
                            left: 2.6vw;

                            span {
                                @media (min-width: 1024px) {
                                    left: 1vw;
                                    top: -3.5vw;
                                    white-space: pre;
                                }
                            }
                        }

                        .copy__wrapper {
                            @media (min-width: 1024px) {
                                margin-top: -2.8vw;
                            }
                        }
                    }

                    .grid-inner:nth-of-type(odd) {
                        order: initial;
                        @media (min-width: 1024px) {
                            order: -1;
                            margin-top: -8vw;

                        }
                    }

                    .grid-inner:nth-of-type(even) {
                        @media (min-width: 1024px) {
                            padding: 0px 7vw 0 3vw;
                        }
                    }
                }
            }
        }
        .section_3 {
            &.outer-grid-wrapper {
                .grid-wrapper {
                    margin-top: 16vw;

                    .grid-inner {
                        .header-wrapper {
                            top: -8.5vw;
                            left: 9.6vw;

                            span {
                                @media (min-width: 1024px) {
                                    left: -18vw;
                                    top: -2.3vw;
                                    white-space: pre;
                                }
                            }
                        }

                        .copy__wrapper {
                            @media (min-width: 1024px) {
                                margin-top: -2.8vw;
                            }
                        }
                    }

                    .grid-inner:nth-of-type(even) {
                        order: initial;

                        @media (min-width: 1024px) {
                            order: -1;
                        }
                    }

                    .grid-inner:nth-of-type(even) {
                        @media (min-width: 1024px) {
                            padding: 0px 3vw 0 7vw;
                        }
                    }
                }
            }
        }

        .section_4 {
            &.outer-grid-wrapper {
                .grid-wrapper {
                    margin-top: 15vw;

                    .grid-inner {
                        .header-wrapper {
                            top: -8.5vw;
                            left: 5.6vw;
                            white-space: pre;

                            span {
                                @media (min-width: 1024px) {
                                    left: -3vw;
                                    top: -2.8vw;
                                }
                            }
                        }

                        .copy__wrapper {
                            @media (min-width: 1024px) {
                                margin-top: -2.8vw;
                            }
                        }
                    }
                    .grid-inner:nth-of-type(odd) {
                        @media (min-width: 1024px) {
                            margin-top: -8vw;
                        }
                    }

                    .grid-inner:nth-of-type(even) {
                        order: initial;

                        @media (min-width: 1024px) {
                            order: 1;
                            padding: 0px 7vw 0 3vw;
                        }
                    }
                }
            }
        }

        background: $viva-irl;
        background: linear-gradient(to bottom, #cf235c 3%, #c883a7 27%, #c881a9 93%, #c881a9 93%);
        background-attachment: local;
    }
}
