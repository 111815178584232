@import './../../variable.scss';

.home-mobile {
    // &.hide {
    //     background-color: #000;
    //     display: none;
    // }

    z-index: 1;
    @media(min-width: 1024px){
        display: none;
    }

    .carousel-slider {
        width: 100vw;
        height: 100vh;

        .pillar-mobile {
            display: flex;
            height: 100vh;
            width: 100vw;
            box-sizing: border-box;
            background-position: center calc(50% - 39vh);
            background-size: cover;

            &__body {
                align-self: flex-end;
                height: 39vh;
                width: 100%;

                @media(min-width: 700px) {
                    // height: 47vh;  
                }

                h2 {
                    margin: 0;
                    // font-size: 98px !important;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 24vh;
                    
                }

                button {
                    position: absolute;
                    width: 40vw;
                    min-width: 144px;
                    left: 0;
                    right: 0;
                    bottom: 15%;
                    margin: 0 auto;
                    text-align: center;
                    font-family: CorporateS;
                    font-weight: 500;
                    background-color: transparent;
                    border: 3px solid #000;
                    color: #000;
                    text-transform: uppercase;
                    text-decoration: none;
                    padding: 15px 32px;
                    // white-space: nowrap;
                    cursor: pointer;

                    &:hover {
                        background-color: #000;
                        color: #fff;
                    }
                }
            }
            &.editors-letter{
                    background-color: $editors-letter;
                    background-image: url(./../../assets/pillars/editor-letter/corner-paper-edge.png), url(./../../assets/pillars/editor-letter/3-lockup.png);
                    background-repeat: no-repeat;
                    background-size: auto 21vh, auto 61%;
                    background-position: left top, calc(0% + 7vw) calc(10% + 2vh);
                .pillar-mobile__body{
                    background-color: $editors-letter;
                }
                h2 {
                    font-size: 21vw;
                    margin-top: -4%;
                    
                    .word-1{
                        font-family: CorporateS;
                        font-weight: bold;
                        -webkit-text-stroke: 3px #fff;
                        color: transparent;
                        text-transform: uppercase;
                    }
                    .word-2{
                        position: absolute;
                        left: 4%;
                        font-size: 75%;
                        font-family: dearJoe6;
                        font-weight: normal;
                        -webkit-text-stroke: 0;
                        color: $charcoal;
                        margin-top: 8%;
                    }
                    @media(min-width: 425px){
                        font-size: 19vw;
                        .word-2{
                            left: 10%;
                        }
                    }
                    @media(min-width: 700px){
                        font-size: 14vw;
                        .word-2{
                            left: 21%;
                            margin-top: 7%;
                        }
                    }

                }
                @media(min-width: 700px){
                    background-repeat: no-repeat;
                    background-size: auto 21vh, auto 61%;
                    background-position: left top, calc(50% + 7vw) calc(10% + 2vh);
                }
                @media(min-width: 1024px){
                    background-image: url(./../../assets/pillars/editor-letter/corner-paper-edge.png), url(./../../assets/pillars/editor-letter/el-mobile@3x.png);
                    background-size: auto 21vh,61vh auto;
                    background-position: left top, center calc(0% - 17vh);
                }
            }
            &.self-stasis,
            &.rational-reset {
                h2{
                    margin-top: -7%;
                    .word-1{
                        position: absolute;
                        margin-top: 11%;
                        font-family: dearJoe6;
                        font-size: 55%;
                        font-weight: normal;
                        -webkit-text-stroke: 0;
                        color: $charcoal;
                    }
                    .word-2{
                        font-family: CorporateS;
                        font-weight: bold;
                        -webkit-text-stroke: 3px #fff;
                        color: transparent;
                        text-transform: uppercase;
                    }
                }
            }
            &.self-stasis{
                background-image: url(./../../assets/pillars/self-stasis/ss-mobile.png);

                
                .pillar-mobile__body{
                    background-color: $self-stasis;
                }
                h2 {
                    font-size: 22vw;
                    margin-top: -4%;
                    @media(min-width: 425px){
                        font-size: 19vw;

                        .word-1{
                            left: 18%;
                            margin-top: 9%;
                        }
                    }
                    @media(min-width: 700px){
                        font-size: 14vw;
                        .word-1{
                            left: 27%;
                            margin-top: 8%;
                        }
                    }
                }
                
            }
            &.rational-reset{
                background-image: url(./../../assets/pillars/rational-reset/rr-mobile.png);
                .pillar-mobile__body{
                    background-color: $rational-reset;
                }
                h2{
                    font-size: 23vw;
                    margin-top: -3%;

                    @media(min-width: 425px){
                        font-size: 16vw;

                        .word-1{
                            left: 21%;
                            margin-top: 10%;
                        }
                    }
                    @media(min-width: 700px){
                        font-size: 14vw;
                        .word-1{
                            left: 30%;
                            margin-top: 8%;
                        }
                    }
                }
            }
            &.viva-irl{
                background-image: url(./../../assets/pillars/viva-irl/vi-mobile.png);
                .pillar-mobile__body{
                    background-color: $viva-irl;
                }
                h2{
                    font-size: 22vw;
                    font-family: CorporateS;
                    font-weight: bold;
                    -webkit-text-stroke: 3px #fff;
                    color: transparent; 
                    white-space: nowrap;
                    text-transform: uppercase;
                    @media(min-width: 425px){
                        font-size: 19vw;

                        .word-1{
                            left: 21%;
                            margin-top: 10%;
                        }
                    }
                    @media(min-width: 700px){
                        font-size: 14vw;
                        .word-1{
                            left: 30%;
                            margin-top: 8%;
                        }
                    }
                }
            }
            &.share {
                padding: 0 20%;
                background: $share url(./../../assets/core/socials/mobile-plus.png) no-repeat;
                background-size: contain;
                color: #fff;

                .pillar-mobile__body{
                    height: 70vh;
                    width: 100vw;
                    margin: auto;

                    @media(min-width: 560px) {
                        width: 40vw;
                        // .share__contact{
                        //     font-size: 6vw;
                        // }
                        .socials{
                            justify-content: center;
                            img{
                                width: calc(8vh + 1vw);
                            }
        
                        }
                    }

                    @media(min-width: 768px) {

                        .socials{
                            img{
                                width: calc(8vh + 2vw);
                                margin: 4.5vh 4.5vw;
                            }
                        }
                    }
                }

                h2 {
                    position: relative;
                    bottom: 0;
                    text-align: left;
                    font-family: CorporateS;
                    font-size: calc(5vh + 3vw);
                    line-height: .8;
                    margin: 4% 0 5%;
                    text-transform: capitalize;


                }

                .share__contact{
                    font-family: CorporateS;
                    font-size: calc(4vh + 2vw);
                    color: #fff;
                    font-weight: bold;
                    text-decoration: none;

                    &:hover{
                        text-decoration: underline;
                    }
                }

                .socials {
                    justify-content: center;
                    img{
                        // flex: 25% 4;
                        width: calc(9vh - 6vw);
                        margin: 2.5vh 2.5vw;
                    }


                    
                }
            }
        }
        .control-arrow {
            height: 50px;
            margin-top: unset;
            margin: auto 0;
            background: rgba(0, 0, 0, 0.94); 
            &:hover {
                background: rgba(0, 0, 0, 1) 
            }
        }
    }
    .control-dots {
        bottom: 8%;
        .dot{
            width: 13px;
            height: 13px;
            box-shadow: none;
            background-color: transparent;
            border: 1.9px solid #000;
            border-radius: unset;
            opacity: 1;
            outline: none;
            transition: border-color .5s ease-in-out;

            &.selected {
                background-color: #000;
                transition: background-color .5s ease-in-out;
            }
        }

    }
    &.white {
        .control-dots{
            .dot{
                border-color: #fff;
                &.selected {
                    background-color: #fff;
                }
            }
            
        }
    }
}
