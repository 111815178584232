// Font Size
@font-face {
    font-family: CorporateS;
    font-weight: bold;
    src: url(./assets/fonts/CorporateS-ExtraBold.otf);
}

@font-face {
    font-family: CorporateS;
    font-weight: 500;
    src: url(./assets/fonts/CorporateS-Regular.otf);
}

@font-face {
    font-family: CorporateS;
    font-weight: 300;
    src: url(./assets/fonts/CorporateS-Light.otf);
}

@font-face {
    font-family: dearJoe6;
    src: url(./assets/fonts/dearJoe6.otf);
}

@font-face {
    font-family: CorporateS-Demi;
    src: url(./assets/fonts/CorporateS-Demi.otf);
}

// FS Industrie
@font-face {
  font-family: FSIndustrie;
  font-weight: 100;
  src: url(./assets/fonts/FSIndustrie/FSIndustrie-Light.otf);
}

@font-face {
  font-family: FSIndustrie;
  font-weight: 400;
  src: url(./assets/fonts/FSIndustrie/FSIndustrie-Regular.otf);
}

@font-face {
  font-family: FSIndustrie;
  font-weight: 700;
  src: url(./assets/fonts/FSIndustrie/FSIndustrie-Bold.otf);
}


// Core
$login: #000;
$charcoal:#2d2a26;
$placeholder-copy:#c3c3c3;

//Global Colors

$white: #ffff;
$yellow: #ffe066;

// Pillar Colors
$editors-letter: #e1175f;
$self-stasis:#e0dd1f;
$rational-reset:#2b8db2;
$viva-irl:#cf235c;
$share:#0f0f0f;


// Callout Colors
$callout-red:#d8282a;
$callout-blue:#80a2f9;



