@import "./../../variable.scss";

.pillar {
    // display: flex;
    position: relative;
    min-width: 100vw;
    height: 100vh;
    overflow: hidden;

    [class^="animation"] {
        position: absolute;
    }

    &__wtf {
        display: block;
        width: 60%;
        justify-self: flex-start;
    }

    &__bar {
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 36%;
        height: 100%;
    }

    &__text {
        width: 40vw;
        z-index: 2;
        margin-bottom: 21vh;

        button {
            margin: 0 34px;
            text-align: center;
            background-color: transparent;
            color: #000;
            border: 3px solid #000;
            text-transform: uppercase;
            padding: 15px 32px;
            cursor: pointer;

            &:hover{
                background-color: #000;
                color: #fff;
            }
        }
    }

    &.editors-letter {
        background-color: $editors-letter;
        background-image: 
            url(./../../assets/pillars/editor-letter/mask-invert-3.png),
            url(./../../assets/pillars/editor-letter/img-editors-letter.png);
        background-size: auto 150%, auto 100%;
        background-position: calc(70% + 5vw) center, center right -221%;

        .pillar__wtf {
            margin-bottom: 29px;
        }
        .pillar__bar {
            
            background-image: url(./../../assets/pillars/editor-letter/corner-paper-edge.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top left;
        }
        .pillar__text{
            margin-left: 7vw;
            margin-top: -7vw;
            margin-bottom: 28vh;

            button {
                background-color: $editors-letter;
                &:hover{
                    background-color: #000;
                }
            }
        }
        h2 {
            font-size: 5vw;
            font-family: dearJoe6;
            font-weight: normal;
            -webkit-text-stroke: 0;
            color: $charcoal;
            margin: 0;
            white-space: nowrap;
            span {
                position: absolute;
                margin: 0;
                font-family: CorporateS;
                font-weight: bold;
                font-size: 9.5vw;
                margin-top: -6%;
                margin-left: -5%;
                text-transform: uppercase;
                -webkit-text-stroke: 3px #fff;
                color: transparent;
                z-index: -1;
            }
        }
    }

    &.self-stasis,
    &.rational-reset {
        h2 {
            font-family: CorporateS;
            font-size: 9.5vw;
            text-transform: uppercase;
            -webkit-text-stroke: 3px #fff;
            color: transparent;
            margin: 0;
            span {
                font-family: dearJoe6;
                font-weight: normal;
                font-size: 55%;
                text-transform: initial;
                -webkit-text-stroke: 0;
                position: absolute;
                margin-top: 6%;
                margin-left: 5%;
                color: $charcoal;
            }
        }
    }

    &.self-stasis {
        background-image:
            url(./../../assets/pillars/self-stasis/bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: calc(50% + -10vw) center;
        .animation-sun {
            width: 35vh;
            height: 100%;
            background-image: url(./../../assets/pillars/self-stasis/img-ss-circle-texture.png);
            background-repeat: no-repeat;
            background-size: 100%;
            transform-origin: 50% 16%;
            animation:spin 7s linear infinite; 
            margin-left: 51%;

        }
        .animation-camel {
            width: 10vh;
            height: 100%;
            background-image: url(./../../assets/pillars/self-stasis/img-ss-camel.png);
            background-repeat: no-repeat;
            background-position: 50% 60% ;
            background-size: contain;
            transform-origin: 50% 60%;
            margin-left: 31%;
            z-index: 0;

            &.walk {
            animation:walk 3s .5s ease-in; 
            animation-fill-mode: forwards;
            }
        }
        .animation-bush{
            width: 59vh;
            height: 100%;
            background-image: url(./../../assets/pillars/self-stasis/img-ss-josh-calabrese.png);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: left bottom ;
            transform-origin: -20% 50%;
            animation:breeze-left 5s ease-in-out infinite; 
            margin-left: -7%;
            z-index: 2;

        }
        .pillar__midground {
            position: absolute;
            min-width: 100vw;
            height: 100vh;
            background-image: 
                url(./../../assets/pillars/self-stasis/img-ss-man-on-rock.png),
                url(./../../assets/pillars/self-stasis/g-midground.png);
            background-repeat: no-repeat;
            background-size: 52vh auto, 38vw auto;
            background-position: 
            calc(42%) calc(0% + 18vh), 
            calc(8%) calc(50% + 0vh);
            z-index: 1;
        }
        .pillar__bar {
            background-color: $self-stasis;
            float: right;
            text-align: right;
            z-index: 2;

        }            
        .pillar__text {
            margin-left: 17vw;
        }
    }

    &.rational-reset {
        background-image: 
        url(./../../assets/pillars/rational-reset/rational-reset-for.png),
        url(./../../assets/pillars/rational-reset/rational-reset-mid.png),
        url(./../../assets/pillars/rational-reset/rational-reset-bg.png);
        background-repeat: no-repeat;
        background-size: 20vw auto, 65vw auto, cover;
        background-position: calc(33% + -2vw) calc(50% + 8vh), calc(-67% + 20vw) calc(90% + 8vh), calc(50% + -22vw) center;
        .animation-xwoman{
            width: 54vh;
            height: 100%;
            background: url(./../../assets/pillars/rational-reset/Try-Curious-Retail.gif) no-repeat;
            background-size: contain;
            background-position: 50% 119%;
            margin-left: 41%;
        }
        .animation-eye {
            width: 29vh;
            height: 100%;
            background: url(./../../assets/pillars/rational-reset/img-rr-mythbusting.png) no-repeat;
            background-size: contain;
            background-position: 50% 77%;
            margin-left: 29%;
            animation: search 5s infinite;
        }
        .pillar__bar {
            background-color: $rational-reset;
            
            float: right;
            text-align: right;
        }
        .pillar__text {
            margin-left: 37vw;
        }
    }

    &.viva-irl {
        background-image: url(./../../assets/pillars/viva-irl/viva-irl-bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: calc(50% + -32vw) center;
        .animation-butterfly1 {
            width: 17vh;
            height: 100%;
            background: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_01.png) no-repeat;
            background-size: contain;
            background-position: 50% 39%;
            margin-left: 16%;
            animation: flutter 1s infinite;
            z-index: 4;
        }
        .animation-butterfly2 {
            width: 13vh;
            height: 100%;
            background: url(./../../assets/pillars/viva-irl/ButterflyB_FlapWings_01.png) no-repeat;
            background-size: contain;
            background-position: 50% 41%;
            margin-left: 30%;
            animation: flutter2 1s infinite;
            z-index: 4;
        }
        .animation-rocket {
            width: 25vh;
            height: 100%;
            background-image: url(./../../assets/pillars/viva-irl/img-virl-pexels-matheus.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 61%;
            margin-left: 20%;
            &.fly{
                animation: lift-off 2s 1s ease-in;
                animation-fill-mode: forwards;
            }
        }
        .animation-flicker {
            width: 100%;
            height: 100%;
            background-color: blue;
            mix-blend-mode: multiply;
            opacity: .2;
            z-index: 0;
            animation: flicker 4s infinite;
        }
        .pillar__midground {
            position: absolute;
            min-width: 100vw;
            height: 100vh;
            background-image: 
                url(./../../assets/pillars/viva-irl/viva-irl-for.png),
                url(./../../assets/pillars/viva-irl/viva-irl-mid.png);
            background-repeat: no-repeat;
            background-size: 50vw auto, 65vw auto;
            background-position: calc(29%) calc(90% + 14vh), calc(0%) calc(100% + 14vh);
            z-index: 1;
        }
        .pillar__bar {
            background-color: $viva-irl;
            float: right;

            z-index: 2;
        }
        .pillar__text {
            min-width: 37vw;
            margin-left: 59vw;
            h2 {
                display: block;
                font-family: CorporateS;
                font-size: 9vw;
                text-transform: uppercase;
                -webkit-text-stroke: 3px #fff;
                color: transparent;
                margin: 0;
                span {
                    position: relative;
                }
            }
        }
    }

    &.share {
        background-color: $share;
        background-image: url(./../../assets/core/socials/plus-lockup-desktop.png);
        background-size: contain;
        color: #fff;
        .pillar__wtf {
            width: 40%;
        }
        .pillar__bar {
            margin: 0 auto;
            width: 50%;
        }
        .pillar__text {
            display: flex;
            flex-direction: column;
            position: relative;
            min-width: 40vw;
            // max-width: 500px;
            margin-left: 88vw;
            margin-bottom: 35vh;

            .share__contact{
                font-family: CorporateS;
                font-size: 4vw;
                color: #fff;
                font-weight: bold;
                text-decoration: none;
                margin-top: 4vh;
                text-align: center;

                &:hover{
                    text-decoration: underline;
                }
            }

            h2 {
                position: relative;
                font-family: CorporateS;
                font-size: 5.2vw;
                line-height: .8;
                margin: 4% 0 5%;
                text-transform: capitalize;
            }
        }
    }
}

@-moz-keyframes spin { 
    100% { 
        -moz-transform: rotate(360deg); 
    } 
}
@-webkit-keyframes spin { 
    100% {
        -webkit-transform: rotate(360deg); 
    } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform: rotate(360deg); 
    } 
}

@keyframes breeze-left {
    0% { transform: rotate(0deg);}
    25% { transform: rotate(5deg);}
    50% { transform: rotate(3deg);}
    75% { transform: rotate(7deg);}
    100% { transform: rotate(0deg);}
}

@keyframes search {
    0% { background-image: url(./../../assets/pillars/rational-reset/mythbusting-left.png); }
    20% { background-image: url(./../../assets/pillars/rational-reset/mythbusting-left.png); }
    21% { background-image: url(./../../assets/pillars/rational-reset/mythbusting-right.png); }
    40% { background-image: url(./../../assets/pillars/rational-reset/mythbusting-right.png); }
    41% { background-image: url(./../../assets/pillars/rational-reset/mythbusting.png); }
    50% { background-image: url(./../../assets/pillars/rational-reset/mythbusting.png); }
    51% { background-image: url(./../../assets/pillars/rational-reset/mythbusting-left.png); }
    70% { background-image: url(./../../assets/pillars/rational-reset/mythbusting-left.png); }
    71% { background-image: url(./../../assets/pillars/rational-reset/mythbusting-right.png); }
    100% { background-image: url(./../../assets/pillars/rational-reset/mythbusting-right.png); }
    
}

@keyframes lift-off {
    0% { background-position: 50% 61%; margin-left: 20%; }
    100% { background-position: 50% 40%; margin-left: 30%; }
}

@keyframes flutter {
    0% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_02.png); }
    14% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_02.png); }
    15% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_01.png); }
    29% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_01.png); }
    30% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_02.png); }
    49% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_02.png); }
    50% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_01.png); }
    60% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_01.png); }
    100% { background-image: url(./../../assets/pillars/viva-irl/ButterflyA_FlapWings_01.png); }
}

@keyframes flutter2 {
    0% { background-image: url(./../../assets/pillars/viva-irl/ButterflyB_FlapWings_02.png); }
    29% { background-image: url(./../../assets/pillars/viva-irl/ButterflyB_FlapWings_01.png); }
    30% { background-image: url(./../../assets/pillars/viva-irl/ButterflyB_FlapWings_02.png); }
    39% { background-image: url(./../../assets/pillars/viva-irl/ButterflyB_FlapWings_02.png); }
    40% { background-image: url(./../../assets/pillars/viva-irl/ButterflyB_FlapWings_01.png); }
    60% { background-image: url(./../../assets/pillars/viva-irl/ButterflyB_FlapWings_01.png); }
    100% { background-image: url(./../../assets/pillars/viva-irl/ButterflyB_FlapWings_02.png); }
}

@keyframes flicker {
    0% { background-color: blue;}
    20% { background-color: pink;}
    40% { background-color: blue;}
    60% { background-color: magenta;}
    80% { background-color: blueviolet;}
    100% { background-color: blue;}
}

@keyframes walk {
    0% { transform: rotate(0deg) translate(0, 0) ;}
    4% { transform: rotate(0deg) translate(0, 0) ;}
    5% { transform: rotate(20deg) translate(-20%, -2%) ;}
    9% { transform: rotate(20deg) translate(-20%, -2%) ;}
    10% { transform: rotate(-9deg) translate(-45%, 1%) ;}
    14% { transform: rotate(-9deg) translate(-45%, 1%) ;}
    15% { transform: rotate(6deg) translate(-60%, 0%) ;}
    19% { transform: rotate(6deg) translate(-60%, 0%) ;}
    20% { transform: rotate(-15deg) translate(-80%, 0%) ;}
    24% { transform: rotate(-15deg) translate(-80%, 0%) ;}
    25% { transform: rotate(5deg) translate(-100%, 0%) ;}
    100% { transform: rotate(5deg) translate(-100%, 0%) ;}
}