@import './../../variable.scss';

.intro-vid {
    display: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow: hidden;
    transition: all 1s ease-out;

    left: 0vw;
    background-color: #000;

    video {
        position: absolute;
        top: 50%; 
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        min-width: 100%; 
        min-height: 100%; 
        width: auto; 
        height: auto;
        z-index: -1000; 
        overflow: hidden;
    }

    &.leave{
        left: -100vw;
        width: 0vw;

    }
    @media(min-width:1024px){
        display: block;
    }
}