.header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    z-index: 10;
    mix-blend-mode: difference;
    overflow: hidden;
    top: 0vh;
    transition: background-color 0.5s ease-in-out, top 0.5s, mix-blend-mode .5s;

    &__logo{
        margin: 2vh 1vw;
        
        img {
            width: 17vw;
            min-width: 50px;
            height: auto;
            fill: #fff;
            transition: width 0.5s ease;

            @media(min-width: 1024px){
                width: 9vw;
            }
        }
    }


    &__logo {
        justify-self: flex-start;

    }
    
    &__menu {
        align-self: flex-start;
        margin: 3.5vh 1.5vw;
        background-color: transparent;
        border: none;
        font-size: 0;
        cursor: pointer;

        img {
            min-width: 40px;
        }
    }

    &.white{
        mix-blend-mode: unset;
        width: calc(100vw - 15px);
    }

    &.sticky-nav {
        background-color: #000;
        z-index: 9;
        mix-blend-mode: unset;
        width: 100vw;

        img {
            align-self: center;
            // width: 40px;
            height: 100%;
        }

        .header__menu {
            margin: 2.7vh 1.5vw;
        }

        .header__logo {
            margin: 1vh 1vw;

            img {
                width: 10vw;
                min-width: 70px;
                max-width: 115px;
            }
        }

        .header__logo-marks{
            align-self: center;
            width: 10vw;
            margin-right: 2vw;
            min-width: 80px;
            max-width: 150px;
            img{
                width: 100%;
            }
        }
    }

    &.hide {
        top: -17vh;
    }

}